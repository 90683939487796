<template>
  <div>
    <div v-if="!isStatement && hasStatement &&
           (engine == 'adaptivepathengine' || engine == 'linearstandaloneengine')"
         class="back-to-statement"
    >
      <b-button
        v-b-tooltip.hover
        :title="$t('navigations.back-to-statement')"
        variant="flat-info"
        style="color: white;"
        class="btn-icon"
        @click="isStatement = true"
      >
        <feather-icon icon="RewindIcon"
                      size="35"
        />
      </b-button>
    </div>
    <portal v-if="canHaveTimer && problem.rules && problem.rules.task_time && problem.rules.preparation_time"
            to="problemTaskTimerPortal"
    >
      <Timer v-if="hasTaskTimerStarted"
             :key="problem.rules.task_time + problem?.id"
             ref="taskTimer"
             type="task"
             :time="problem.rules.task_time"
      />
      <Timer v-else
             :key="problem.rules.preparation_time + problem?.id"
             :time="problem.rules.preparation_time"
             type="prepare"
             @onEnd="(time) => { hasTaskTimerStarted = true; preparationTime = time }"
      />
    </portal>
    <!-- statement -->
    <!-- <template v-if="isStatement && (engine !== 'carryforwardengine' || engine !== 'doublefallbackengine')"> -->
    <template
      v-if="
        hasStatement && isStatement &&
          (engine == 'adaptivepathengine' || engine == 'linearstandaloneengine')
      "
    >
      <video-statement
        v-if="isVideo"
        :level="level"
        :video="problem.video"
        :is-last-statement="true"
        :engine="engine"
        @continueToProblem="skipProblemPart ? nextProblem() : moveToQuestion()"
      />
      <div v-else-if="problem.hotspotPoints">
        <animated-layout
          :enabled="problem.animated_layout"
          :is-edit-mode="false"
          teleport-target="problemFeedBack"
          :template-id="problem.animated_template_id"
        >
          <hot-spot-statement

            :hotspot="problem"
            :level="level"
            :engine="engine"
            :school-settings="schoolSettings"
            :district-settings="districtSettings"
            @submitQuestion="submitQuestion"
            @proceedToQuestion="moveToQuestion()"
            @nextProblem="nextProblem"
          />
        </animated-layout>
      </div>
      <AnimatedLayout v-else
                      :enabled="problem.st_template && !!problem.st_template.animated_layout"
                      :template-id="problem.st_template && problem.st_template.animated_template_id"
                      :is-edit-mode="false"
                      teleport-target="statementActionForAnimationLayout"
      >
        <statement
          :statement="{
            text: problem.statement,
            image: problem.statement_image,
            audio: problem.statement_audio,
            template: problem.st_template,
            game:
              problem.game && problem.game.length > 0 ? problem.game[0] : null,
          }"
          :level="level"
          :is-last-statement="true"
          :engine="engine"
          :loader-id="loaderId"
          :school-settings="schoolSettings"
          :district-settings="districtSettings"
          @continueToProblem="moveToQuestion()"
        />
      </AnimatedLayout>
    </template>
    <!-- statement -->

    <!-- Problem -->
    <template v-else>
      <!-- <hot-spot
        v-if="problem.hotspotPoints"
        :problem="problem"
        :duration="duration"
        :pid="pid"
        :engine="engine"
        :level="level"
        :loader-id="loaderId"
        @nextQuestion="nextProblem"
      /> -->
      <animated-layout
        :enabled="problem.animated_layout"
        :is-edit-mode="false"
        :is-problem-body-preview="true"
        teleport-target="problemFeedBack"
        :template-id="problem.animated_template_id"
      >
        <component
          :is="getProblemComponent"
          :questions="problem.questions"
          :feedback="feedback ? feedback : {}"
          :feedback-question="feedBackQuestion"
          :mode="feedback ? 'feedback' : 'question'"
          :level="level"
          :is-processing="false"
          :rules="rules"
          :engine="engine"
          :has-minimal-view="hasMinimalView"
          :school-settings="schoolSettings"
          :district-settings="districtSettings"
          @submitQuestion="submitQuestion"
          @nextQuestion="nextProblem"
        />
      </animated-layout>
    </template>
    <!-- Problem -->
    <audio
      ref="successAudio"
      src="https://assets.mixkit.co/sfx/preview/mixkit-fantasy-game-success-notification-270.mp3"
    />
    <BadgesCelebration v-if="obtainedBadges"
                       :key="problem?.id"
                       :badge-info="obtainedBadges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BOverlay, BButton, VBTooltip } from 'bootstrap-vue'
import {
  PROBLEM_TYPE_MATCH,
  PROBLEM_TYPE_FILL_BLANK,
  PROBLEM_TYPE_FILL_DROPDOWN,
  PROBLEM_TYPE_MULTIPLE,
  PROBLEM_TYPE_SPEECH,
  PROBLEM_TYPE_CATEGORY,
  PROBLEM_DRAG_POSITION,
  OPEN_END_WRITING,
  OPEN_END_WRITING_SPEECH,
  TEXT_SELECTION,
  PROBLEM_TYPE_MATCH_LISTENING,
  PROBLEM_TYPE_MULTIPLE_LISTENING,
} from '@/const/problemTypes'

import { STATUS_RIGHT, STATUS_WRONG, STATUS_IDLE } from '@/const/avatar'
import useJwt from '@/auth/jwt/useJwt'
import AnimatedLayout from '@/views/super/problem/body-template/manager.vue'
import problemMethods from '@/utils/problem'
import answerFormatter from '@/utils/problemFeedbackFormatter'
import Statement from './compoments/Statement.vue'
import MatchProblem from './compoments/MatchProblem.vue'
import FillProblem from './compoments/FillProblem.vue'
import FillProblemDropdown from './compoments/FillProblemDropdown.vue'
import MultipleProblem from './compoments/MultipleProblem.vue'
import SpeechProblem from './compoments/SpeechProblem/index.vue'
import VideoStatement from './compoments/VideoStatement.vue'
import HotSpotStatement from './hotspot/HotSpotStatement.vue'
// import HotSpot from './hotspot/HotStop.vue'
import CategoryProblem from './compoments/CategoryProblem.vue'
import OpenEndWriting from './compoments/OpenEndWriting.vue'
import TextSelection from './compoments/TextSelection.vue'
import DragPosition from './compoments/DragPosition/index.vue'
import Timer from './compoments/Timer.vue'
import BadgesCelebration from './compoments/BadgesCelebration.vue'

export default {
  components: {
    Statement,
    MatchProblem,
    FillProblemDropdown,
    FillProblem,
    AnimatedLayout,
    MultipleProblem,
    SpeechProblem,
    VideoStatement,
    HotSpotStatement,
    // HotSpot,
    BOverlay,
    CategoryProblem,
    OpenEndWriting,
    TextSelection,
    BButton,
    DragPosition,
    Timer,
    BadgesCelebration,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    problem: {
      type: Object,
      default: () => {},
    },
    pid: {
      type: Number,
      required: true,
    },
    hasStatement: {
      type: Boolean,
      default: () => false,
    },
    level: {
      type: Number,
      default: 1,
    },
    engine: {
      type: String,
      default: () => '',
    },
    fromGuest: {
      type: Boolean,
      default: false,
    },
    loaderId: {
      type: [String, Object, Number],
      default: () => null,
    },
    userInput: {
      type: [Array, Object],
      default: () => {},
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    isClassTest: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
    isSdpTest: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    startTime: {
      statement: 0,
      body: 0,
      feedback: 0,
    },
    duration: {
      statement: 0,
      body: 0,
      feedback: 0,
    },
    feedback: null,
    isStatement: false,
    skipProblemPart: false,
    isCorrect: true,
    testId: false,
    characterTimeout: null,
    hasTaskTimerStarted: false,
    preparationTime: '',
    obtainedBadges: null,
  }),
  computed: {
    canHaveTimer() {
      return !!this.problem.problem_type?.startsWith('open-end') && this.isClassTest
    },
    rules() {
      return this.JSON_PARSE(this.problem.rules)
    },
    getProblemComponent() {
      let type = ''
      if (this.problem.problem_type === PROBLEM_TYPE_MATCH) type = 'MatchProblem'
      else if (this.problem.problem_type === PROBLEM_TYPE_FILL_DROPDOWN) type = 'FillProblemDropdown'
      else if (this.problem.problem_type === PROBLEM_TYPE_FILL_BLANK) type = 'FillProblem'
      else if (this.problem.problem_type === PROBLEM_TYPE_SPEECH) type = 'SpeechProblem'
      else if (this.problem.problem_type === PROBLEM_TYPE_MULTIPLE) type = 'MultipleProblem'
      else if (this.problem.problem_type === PROBLEM_TYPE_CATEGORY) type = 'CategoryProblem'
      else if (this.problem.problem_type === OPEN_END_WRITING) type = 'OpenEndWriting'
      else if (this.problem.problem_type === OPEN_END_WRITING_SPEECH) type = 'OpenEndWriting'
      else if (this.problem.problem_type === TEXT_SELECTION) type = 'TextSelection'
      else if (this.problem.problem_type === PROBLEM_TYPE_MULTIPLE_LISTENING) type = 'MultipleProblem'
      else if (this.problem.problem_type === PROBLEM_TYPE_MATCH_LISTENING) type = 'MatchProblem'
      else if (this.problem.problem_type === PROBLEM_DRAG_POSITION) type = 'DragPosition'
      return type
    },
    isVideo() {
      return !!this.problem.video
    },
    isLastStamp() {
      let status = false
      const currentTimestamp = this.problem?.video_time_stamp
      if (currentTimestamp) {
        const timestamps = currentTimestamp?.video?.times
        const lastTimestamp = timestamps?.length
          ? timestamps[timestamps.length - 1]
          : null
        status = currentTimestamp?.id === lastTimestamp?.id
      }
      return status
    },
    feedBackQuestion() {
      const template = this.problem?.fb_template?.template || []
      return template.sort(() => Math.random() - 0.5)
    },
    autoPlay() {
      return this.$route.query.autoPlay || false
    },
  },
  watch: {
    problem: {
      deep: true,
      immediate: true,
      handler(value) {
        this.hasTaskTimerStarted = false
        this.$store.commit('problem/UPDATE_ACTIVE_PROBLEM', value)
      },
    },
    isStatement(val) {
      this.$store.commit('studentLab/SET_ACTIVE_LAB_VIEW', val ? 'statement' : 'problem-body')
      if (val && this.autoPlay) {
        setTimeout(() => {
          this.moveToQuestion()
        }, 5000)
      }
    },
  },
  created() {
    this.duration.body = parseInt(new Date().getTime() / 1000, 10)
    if (this.hasStatement) this.isStatement = true
    else this.isStatement = false

    // start statement time
    if (this.isStatement && this.engine !== 'carryforwardengine') this.startTime.statement = new Date().getTime()
    else if (this.engine === 'carryforwardengine') this.startTime.statement = 0
    // start body time
    else this.startTime.body = new Date().getTime()
  },
  methods: {
    backToStatement() {
      this.isStatement = true
      this.$store.commit('studentLab/SET_ACTIVE_LAB_VIEW', 'statement')
    },
    timeStringToSeconds(timeString) {
      const [minutes, seconds] = timeString.split(':').map(Number)
      return minutes * 60 + seconds
    },
    ...mapActions('problem', ['setValidations']),
    submitQuestion(data) {
      // calculate body time (current_time - body_start_time)
      this.duration.body = this.startTime.body !== 0
        ? parseInt((new Date().getTime() - this.startTime.body) / 1000, 10)
        : 0

      this.$store.commit('problem/UPDATE_IS_SUBMITTING', true)

      const extraParm = {}
      if (this.$refs.taskTimer) {
        extraParm.task_time = this.timeStringToSeconds(`${this.$refs.taskTimer.increasingMinutes}:${this.$refs.taskTimer.increasingSeconds}`)
        extraParm.prep_time = this.timeStringToSeconds(this.preparationTime)
      }

      this.checkProblemSolution({
        ...data,
        ...{
          time_spent: this.duration.body + this.duration.statement,
          body_time: this.duration.body,
          pid: this.pid,
          st_time: this.duration.statement,
          loader_id: this.loaderId,
        },
        ...extraParm,
      }).then(response => {
        this.setValidations(response.input)
        if (response.test_id) this.testId = response.test_id
        if (response.feedback || response.audio || response.feedback_template) {
          this.isCorrect = !response.is_wrong
          this.feedback = {
            text: response.feedback || response.input?.text,
            image: response.image,
            audio: response.audio,
            template: response.feedback_template,
            isCorrect: !response.is_wrong,
            grammar: response.grammar, // only for open end writing
            open_ai_score: response.open_ai_score,
            test_id: response.test_id,
            hasSpeechCheck: response.has_speech_feedback,
            is_demo: !!response.is_demo,
          }
          localStorage.setItem('current_test_id', response.test_id)
          this.$emit('checkForGuestReport')
          if (!response.is_wrong) {
            if (this.$refs.successAudio) {
              this.$refs.successAudio.volume = 0.05
              this.$refs.successAudio.play()
            }
            this.$emit('updateStar')
            this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_RIGHT)
          } else {
            this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_WRONG)
          }
          if (this.characterTimeout) clearTimeout(this.characterTimeout)
          this.characterTimeout = setTimeout(() => {
            this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_IDLE)
          }, 7000)
          // start feedback time
          this.startTime.feedback = new Date().getTime()
          this.$root.$emit('MAGIC_FEEDBACK_SHOW', response)
          this.testId = response.test_id
        } else this.nextProblem()
        this.$emit('getHighScore')
        if (this.problem?.rules?.hide_feedback || this.hasMinimalView) {
          this.nextProblem()
        }
        this.obtainedBadges = response.badge_info
      }).finally(() => {
        this.$store.commit('problem/UPDATE_IS_SUBMITTING', false)
      })
    },
    nextProblem() {
      this.obtainedBadges = null
      this.duration.feedback = this.startTime.feedback !== 0
        ? parseInt(
          (new Date().getTime() - this.startTime.feedback) / 1000,
          10,
        )
        : 0
      this.startTime.feedback = 0
      this.startTime.body = new Date().getTime()
      if (!this.fromGuest) {
        useJwt.recordFeedbackTime({
          time: this.duration.feedback,
          test_id: this.testId,
          loader_id: this.loaderId,
        })
      }
      // start statement time
      if (
        this.isLastStamp
        && this.isCorrect
        && (this.engine !== 'carryforwardengine'
          || this.engine !== 'doublefallbackengine')
      ) {
        this.$emit('playLastPart')
        this.skipProblemPart = true
      } else {
        this.feedback = null
        this.skipProblemPart = false
        this.isCorrect = false
        this.$emit('nextProblem')
      }
      if (this.engine !== 'doublefallbackengine') this.isStatement = true
      if (
        this.isStatement
        && (this.engine !== 'carryforwardengine'
          || this.engine !== 'doublefallbackengine')
      ) this.startTime.statement = new Date().getTime()
    },
    moveToQuestion() {
      this.isStatement = false
      if (this.autoPlay) {
        problemMethods.automateProblem(this.problem.problem_type, this.userInput.answer)
      }
      // calculate statement time (current_time - statement_start_time)
      this.duration.statement = this.startTime.statement !== 0
        ? parseInt(
          (new Date().getTime() - this.startTime.statement) / 1000,
          10,
        )
        : 0
      this.startTime.statement = 0
      // start body time
      this.startTime.body = new Date().getTime()
    },
    checkProblemSolution(data) {
      let config = {}
      const formData = new FormData()
      if (this.autoPlay) {
        // eslint-disable-next-line no-param-reassign
        data.answer = this.userInput.answer
        if (!data.answer) {
          // eslint-disable-next-line no-param-reassign
          data.answer = 'This is an answer.'
        }
      }
      if (Array.isArray(data.answer)) {
        config = {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        }
        formData.append('time_spent', data.time_spent)
        if (this.isSdpTest) {
          formData.append('lang_level', this.level)
        } else {
          formData.append('lang_level', data.lang_level)
        }
        formData.append('st_time', data.st_time)
        formData.append('pid', data.pid)
        formData.append('body_time', data.body_time)
        formData.append('loader_id', data.loader_id)

        data.answer.forEach((a, i) => {
          if (a.audio) {
            formData.append(`answer[${a.id}][audio]`, a.audio)
            formData.append(`answer[${a.id}][text]`, a.text)
          } else formData.append(`answer[${i}]`, a)
        })
      }

      const problemChecker = this.fromGuest
        ? 'checkProblemSolutionForDemoUser'
        : 'checkProblemSolution'
      // eslint-disable-next-line no-unused-expressions

      return new Promise((resolve, reject) => {
        useJwt[problemChecker](
          this.problem.id,
          Array.isArray(data.answer) ? formData : data,
          config,
        )
          .then(response => {
            const res = response.data?.data
            resolve(answerFormatter(res))
            setTimeout(() => {
              // eslint-disable-next-line no-unused-expressions
              document.getElementsByClassName('problem-card-footer')?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
            }, 500)
          })
          .catch(err => reject(err))
      })
    },
  },
}
</script>
<style>
  .back-to-statement {
    position:  absolute;
    z-index: 100;
  }
  .timer-for-problem {
    position: absolute;
    z-index: 100;
    left: 0;
  }
</style>
